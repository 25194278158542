import React, { useState } from "react";
import { Box, Button, Flex, Input, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { utils } from "@kuzco/utils";

import { MAX_USERNAME_LENGTH } from "~/lib/const";
import { api } from "~/lib/trpc";
import useUser from "../ui/hooks/useUser.hook";

const SetUsernamePage: React.FC = () => {
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const { user, refreshUser } = useUser();
  const toast = useToast();

  const setUsernameMutation = api.user.setUsername.useMutation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const cleanedUsername = utils.cleanUsername(username);

    try {
      const newUser = await setUsernameMutation.mutateAsync({
        username: cleanedUsername ?? "",
      });
      await refreshUser();

      if (newUser.user?.username) {
        toast({
          title: "Username set successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/dashboard/overview");
      } else {
        toast({
          title: "Username is already taken or invalid! Try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (err) {
      toast({
        title: "Username is already taken or invalid! Try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (user?.username) {
    navigate("/dashboard/overview");
    return null;
  }

  return (
    <Box display="flex" height="100vh" width="100vw" justifyContent="center">
      <Flex
        position="relative"
        top={{ base: "0", md: "96px" }}
        width={{ base: "100%", md: "420px" }}
        height="fit-content"
        flexDirection="column"
        alignItems="center"
        borderRadius="8px"
        boxSizing="border-box"
      >
        <Box height="24px" />
        <Text variant="30-bold" mb={4}>
          Set Your Username
        </Text>
        <Text mb={6} textAlign="center">
          Choose a unique public username to display on leaderboards and in the
          Kuzco community.
        </Text>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Input
            value={username}
            onChange={(e) =>
              setUsername(utils.cleanUsername(e.target.value) ?? "")
            }
            placeholder="Enter your username"
            maxLength={MAX_USERNAME_LENGTH}
            mb={4}
            required
          />
          <Button type="submit" variant="primary" size="lg" width="100%">
            Set Username
          </Button>
        </form>
      </Flex>
    </Box>
  );
};

export default SetUsernamePage;
