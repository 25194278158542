import z from "zod";

import {
  authData,
  authedServiceRequest,
  authToken,
  serviceRequest,
  serviceResponse,
} from "./BaseService";

export const UserWallet = z.object({
  pubkey: z.string(),
  verifiedAt: z.date(),
});

export const U2FKey = z.object({
  label: z.string(),
  credentialID: z.string(),
  publicKey: z.string(),
  counter: z.number(),
});

export const UserSchema = z.object({
  _id: z.string(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  passwordHash: z.string(),
  resetToken: z.string().nullable(),
  resetTokenExpiresAt: z.date().nullable(),
  stripeCustomerId: z.string().nullable(),
  balance: z.number(),
  escrowBalance: z.number(),
  emailVerifiedAt: z.date().nullable(),
  emailVerificationToken: z.string().uuid().nullable(),
  emailVerificationTokenRequestedAt: z.date().nullable(),
  emailVerificationRequired: z.boolean(),
  wallets: UserWallet.array().optional(),
  isDisabled: z.boolean(),
  maxInstancesPerWorkerOverride: z.number().nullable(),
  discordId: z.string().optional(),
  discordUsername: z.string().optional(),
  username: z.string().nullable(),
  otpSecret: z.string().nullable(),
  otpSecretVerifiedAt: z.date().nullable(),
  u2fKeys: U2FKey.array().optional(),
  u2fChallenge: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const UserMetricsSchema = UserSchema.merge(
  z.object({
    onlineWorkerCount: z.number().optional(),
    totalWorkerCount: z.number().optional(),
  }),
);

export const authenticationResponse = serviceResponse.merge(
  z.object({
    user: UserSchema.optional(),
    token: authToken.optional(),
  }),
);

export type User = z.infer<typeof UserSchema>;
export type UserWallet = z.infer<typeof UserWallet>;
export type U2FKey = z.infer<typeof U2FKey>;
export type U2FKeys = U2FKey[] | null;
export type UserMetrics = z.infer<typeof UserMetricsSchema>;
export type AuthenticationResponse = z.infer<typeof authenticationResponse>;

export type UserService = {
  sendEmailVerificationLink(
    request: SendEmailVerificationLinkRequest,
  ): Promise<SendEmailVerificationLinkResponse>;
  verifyEmailVerificationToken(
    request: VerifyEmailVerificationTokenRequest,
  ): Promise<VerifyEmailVerificationTokenResponse>;
  verifySolanaWalletSignature(
    request: VerifySolanaWalletSignatureRequest,
  ): Promise<VerifySolanaWalletSignatureResponse>;
  unlinkSolanaWallet(
    request: UnlinkSolanaWalletRequest,
  ): Promise<UnlinkSolanaWalletResponse>;
  linkDiscordAccount(
    request: LinkDiscordAccountRequest,
  ): Promise<LinkDiscordAccountResponse>;
  unlinkDiscordAccount(
    request: UnlinkDiscordAccountRequest,
  ): Promise<UnlinkDiscordAccountResponse>;
  setUsername(request: SetUsernameRequest): Promise<SetUsernameResponse>;
  login(request: LoginUserRequest): Promise<LoginUserResponse>;
  loginFromKey(
    request: LoginUserFromKeyRequest,
  ): Promise<LoginUserFromKeyResponse>;
  startReset(
    request: StartResetUserPasswordRequest,
  ): Promise<StartResetUserPasswordResponse>;
  finishReset(
    request: FinishResetUserPasswordRequest,
  ): Promise<FinishResetUserPasswordResponse>;
  get(request: GetUserRequest): Promise<GetUserResponse>;
  getUserWallets(
    request: GetUserWalletsRequest,
  ): Promise<GetUserWalletsResponse>;
  superList(request: SuperListUsersRequest): Promise<SuperListUsersResponse>;
  superImpersonate(
    request: SuperImpersonateUserRequest,
  ): Promise<SuperImpersonateUserResponse>;
  systemUser(): Promise<SystemUserResponse>;
  enableOTP(request: EnableOTPRequest): Promise<EnableOTPResponse>;
  verifyOTP(request: VerifyOTPRequest): Promise<VerifyOTPResponse>;
  verifyOTPForLogin(
    request: VerifyOTPForLoginRequest,
  ): Promise<VerifyOTPForLoginResponse>;
  disableOTP(request: DisableOTPRequest): Promise<DisableOTPResponse>;
  generateU2FRegistrationOptions(
    request: GenerateU2FRegistrationOptionsRequest,
  ): Promise<GenerateU2FRegistrationOptionsResponse>;
  verifyU2FRegistration(
    request: VerifyU2FRegistrationRequest,
  ): Promise<VerifyU2FRegistrationResponse>;
  generateU2FAuthenticationOptions(
    request: GenerateU2FAuthenticationOptionsRequest,
  ): Promise<GenerateU2FAuthenticationOptionsResponse>;
  verifyU2FAuthentication(
    request: VerifyU2FAuthenticationRequest,
  ): Promise<VerifyU2FAuthenticationResponse>;
  removeU2FKey(request: RemoveU2FKeyRequest): Promise<RemoveU2FKeyResponse>;
  disableU2F(request: DisableU2FRequest): Promise<DisableU2FResponse>;
  addU2FKey(request: AddU2FKeyRequest): Promise<AddU2FKeyResponse>;
  checkU2FEnabled(
    request: CheckU2FEnabledRequest,
  ): Promise<CheckU2FEnabledResponse>;
};

/** ******************************************************************************
 *  Send Email Verification Link
 ******************************************************************************* */

export const sendEmailVerificationLinkRequest = authedServiceRequest;

export const sendEmailVerificationLinkResponse = serviceResponse.merge(
  z.object({
    user: UserSchema.optional(),
  }),
);

export type SendEmailVerificationLinkRequest = z.infer<
  typeof sendEmailVerificationLinkRequest
>;
export type SendEmailVerificationLinkResponse = z.infer<
  typeof sendEmailVerificationLinkResponse
>;

/** ******************************************************************************
 *  Verify Solana Wallet Signature
 ******************************************************************************* */

export const verifySolanaWalletSignatureParams = z.object({
  signature: z.string(),
  message: z.string(),
  publicKey: z.string(),
});

export const verifySolanaWalletSignatureRequest = authedServiceRequest.merge(
  z.object({
    params: verifySolanaWalletSignatureParams,
  }),
);

export const verifySolanaWalletSignatureResponse = serviceResponse;

export type VerifySolanaWalletSignatureParams = z.infer<
  typeof verifySolanaWalletSignatureParams
>;
export type VerifySolanaWalletSignatureRequest = z.infer<
  typeof verifySolanaWalletSignatureRequest
>;
export type VerifySolanaWalletSignatureResponse = z.infer<
  typeof verifySolanaWalletSignatureResponse
>;

/** ******************************************************************************
 *  Unlink Solana Wallet
 ******************************************************************************* */

export const unlinkSolanaWalletParams = z.object({
  publicKey: z.string(),
});

export const unlinkSolanaWalletRequest = authedServiceRequest.merge(
  z.object({
    params: unlinkSolanaWalletParams,
  }),
);

export const unlinkSolanaWalletResponse = serviceResponse;

export type UnlinkSolanaWalletParams = z.infer<typeof unlinkSolanaWalletParams>;
export type UnlinkSolanaWalletRequest = z.infer<
  typeof unlinkSolanaWalletRequest
>;
export type UnlinkSolanaWalletResponse = z.infer<
  typeof unlinkSolanaWalletResponse
>;

/** ******************************************************************************
 *  Link Discord Account
 ******************************************************************************* */

export const linkDiscordAccountParams = z.object({
  discordId: z.string(),
  discordUsername: z.string(),
});

export const linkDiscordAccountRequest = authedServiceRequest.merge(
  z.object({
    params: linkDiscordAccountParams,
  }),
);

export const linkDiscordAccountResponse = serviceResponse.merge(
  z.object({
    user: UserSchema.nullable().optional(),
  }),
);

export type LinkDiscordAccountParams = z.infer<typeof linkDiscordAccountParams>;
export type LinkDiscordAccountRequest = z.infer<
  typeof linkDiscordAccountRequest
>;
export type LinkDiscordAccountResponse = z.infer<
  typeof linkDiscordAccountResponse
>;

/** ******************************************************************************
 *  Unlink Discord Account
 ******************************************************************************* */

export const unlinkDiscordAccountRequest = authedServiceRequest;

export const unlinkDiscordAccountResponse = serviceResponse.merge(
  z.object({
    user: UserSchema.nullable().optional(),
  }),
);

export type UnlinkDiscordAccountRequest = z.infer<
  typeof unlinkDiscordAccountRequest
>;
export type UnlinkDiscordAccountResponse = z.infer<
  typeof unlinkDiscordAccountResponse
>;

/** ******************************************************************************
 *  Verify Email Verification Link
 ******************************************************************************* */

export const verifyEmailVerificationTokenParams = z.object({
  token: z.string(),
});

export const verifyEmailVerificationTokenRequest = serviceRequest.merge(
  z.object({
    params: verifyEmailVerificationTokenParams,
  }),
);

export const verifyEmailVerificationTokenResponse = serviceResponse;

export type VerifyEmailVerificationTokenParams = z.infer<
  typeof verifyEmailVerificationTokenParams
>;
export type VerifyEmailVerificationTokenRequest = z.infer<
  typeof verifyEmailVerificationTokenRequest
>;
export type VerifyEmailVerificationTokenResponse = z.infer<
  typeof verifyEmailVerificationTokenResponse
>;

/** ******************************************************************************
 *  Login User
 ******************************************************************************* */

export const loginUserParams = z.object({
  email: z.string().email(),
  password: z.string(),
  twoFactorToken: z.string().optional(),
});

export const loginUserRequest = serviceRequest.merge(
  z.object({
    params: loginUserParams,
  }),
);

export const loginUserResponse = authenticationResponse;

export type LoginUserParams = z.infer<typeof loginUserParams>;
export type LoginUserRequest = z.infer<typeof loginUserRequest>;
export type LoginUserResponse = z.infer<typeof loginUserResponse>;

/** ******************************************************************************
 *  Login User From Key
 ******************************************************************************* */

export const loginUserFromKeyParams = z.object({
  apiKey: z.string(),
});

export const loginUserFromKeyRequest = serviceRequest.merge(
  z.object({
    params: loginUserFromKeyParams,
  }),
);

export const loginUserFromKeyResponse = authenticationResponse;

export type LoginUserFromKeyParams = z.infer<typeof loginUserFromKeyParams>;
export type LoginUserFromKeyRequest = z.infer<typeof loginUserFromKeyRequest>;
export type LoginUserFromKeyResponse = z.infer<typeof loginUserFromKeyResponse>;

/** ******************************************************************************
 *  Start Reset User Password
 ******************************************************************************* */

export const startResetUserPasswordParams = z.object({
  email: z.string().email(),
});

export const startResetUserPasswordRequest = serviceRequest.merge(
  z.object({
    params: startResetUserPasswordParams,
  }),
);

export const startResetUserPasswordResponse = serviceResponse.merge(
  z.object({
    success: z.boolean(),
  }),
);

export type StartResetUserPasswordParams = z.infer<
  typeof startResetUserPasswordParams
>;
export type StartResetUserPasswordRequest = z.infer<
  typeof startResetUserPasswordRequest
>;
export type StartResetUserPasswordResponse = z.infer<
  typeof startResetUserPasswordResponse
>;

/** ******************************************************************************
 *  Finish Reset User Password
 ******************************************************************************* */

export const finishResetUserPasswordParams = z.object({
  password: z.string(),
  resetToken: z.string(),
});

export const finishResetUserPasswordRequest = serviceRequest.merge(
  z.object({
    params: finishResetUserPasswordParams,
  }),
);

export const finishResetUserPasswordResponse = serviceResponse.merge(
  z.object({
    success: z.boolean(),
  }),
);

export type FinishResetUserPasswordParams = z.infer<
  typeof finishResetUserPasswordParams
>;
export type FinishResetUserPasswordRequest = z.infer<
  typeof finishResetUserPasswordRequest
>;
export type FinishResetUserPasswordResponse = z.infer<
  typeof finishResetUserPasswordResponse
>;

/** ******************************************************************************
 *  Get User
 ******************************************************************************* */

export const getUserParams = z.undefined();

export const getUserRequest = authedServiceRequest.merge(
  z.object({
    params: getUserParams,
  }),
);

export const getUserResponse = serviceResponse.merge(
  z.object({
    user: UserSchema.nullable().optional(),
  }),
);

export type GetUserParams = z.infer<typeof getUserParams>;
export type GetUserRequest = z.infer<typeof getUserRequest>;
export type GetUserResponse = z.infer<typeof getUserResponse>;

export const getUserWalletsRequest = authedServiceRequest;

export const getUserWalletsResponse = serviceResponse.merge(
  z.object({
    wallets: z.array(UserWallet).optional(),
  }),
);

export type GetUserWalletsRequest = z.infer<typeof getUserWalletsRequest>;
export type GetUserWalletsResponse = z.infer<typeof getUserWalletsResponse>;

/** ******************************************************************************
 *  Set Username
 ******************************************************************************* */

export const setUsernameParams = z.object({
  username: z.string(),
});

export type SetUsernameParams = z.infer<typeof setUsernameParams>;

export const setUsernameRequest = authedServiceRequest.merge(
  z.object({
    params: setUsernameParams,
  }),
);

export const setUsernameResponse = serviceResponse.merge(
  z.object({
    user: UserSchema.nullable().optional(),
  }),
);

export type SetUsernameRequest = z.infer<typeof setUsernameRequest>;
export type SetUsernameResponse = z.infer<typeof setUsernameResponse>;

/** ******************************************************************************
 *  Start Trial
 ******************************************************************************* */

export const startTrialParams = z.undefined();

export const startTrialRequest = authedServiceRequest.merge(
  z.object({
    params: startTrialParams,
  }),
);

export const startTrialResponse = serviceResponse.merge(
  z.object({
    user: UserSchema.nullable().optional(),
  }),
);

export type StartTrialParams = z.infer<typeof startTrialParams>;
export type StartTrialRequest = z.infer<typeof startTrialRequest>;
export type StartTrialResponse = z.infer<typeof startTrialResponse>;

/** ******************************************************************************
 *  Create Stripe Session
 ******************************************************************************* */

export const createStripeSessionParams = z.undefined();

export const createStripeSessionRequest = authedServiceRequest.merge(
  z.object({
    params: createStripeSessionParams,
  }),
);

export const createStripeSessionResponse = serviceResponse.merge(
  z.object({
    sessionUrl: z.string().nullable().optional(),
  }),
);

export type CreateStripeSessionParams = z.infer<
  typeof createStripeSessionParams
>;
export type CreateStripeSessionRequest = z.infer<
  typeof createStripeSessionRequest
>;
export type CreateStripeSessionResponse = z.infer<
  typeof createStripeSessionResponse
>;

/** ******************************************************************************
 *  Super List Users
 ******************************************************************************* */

export const superListUsersParams = z.object({
  _id: z.string().optional(),
  email: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  discordId: z.string().optional(),
  discordUsername: z.string().optional(),
  username: z.string().optional(),
  cursor: z
    .object({
      pageSize: z.number(),
      offset: z.number(),
    })
    .nullish(),
});

export const superListUsersRequest = authedServiceRequest.merge(
  z.object({
    params: superListUsersParams,
  }),
);

export const superListUsersResponse = serviceResponse.merge(
  z.object({
    users: z.array(UserMetricsSchema).optional(),
    total: z.number().optional(),
  }),
);

export type SuperListUsersParams = z.infer<typeof superListUsersParams>;
export type SuperListUsersRequest = z.infer<typeof superListUsersRequest>;
export type SuperListUsersResponse = z.infer<typeof superListUsersResponse>;

/** ******************************************************************************
 *  Super Impersonate User
 ******************************************************************************* */

export const superImpersonateUserParams = z.object({
  userId: z.string(),
});

export const superImpersonateUserRequest = authedServiceRequest.merge(
  z.object({
    params: superImpersonateUserParams,
  }),
);

export const superImpersonateUserResponse = authenticationResponse;

export type SuperImpersonateUserParams = z.infer<
  typeof superImpersonateUserParams
>;
export type SuperImpersonateUserRequest = z.infer<
  typeof superImpersonateUserRequest
>;
export type SuperImpersonateUserResponse = z.infer<
  typeof superImpersonateUserResponse
>;

/** ******************************************************************************
 *  System User
 ******************************************************************************* */

export const systemUserResponse = z.object({
  user: UserSchema,
  auth: authData,
});

export type SystemUserResponse = z.infer<typeof systemUserResponse>;

/** ******************************************************************************
 *  Enable OTP 2FA
 ******************************************************************************* */
export const EnableOTPRequest = authedServiceRequest;
export const EnableOTPResponse = serviceResponse.merge(
  z.object({
    secret: z.string(),
    qrCodeUrl: z.string(),
  }),
);

export const verifyOTPParams = z.object({
  code: z.string(),
});

export const verifyOTPParamsForLogin = z.object({
  email: z.string().email(),
  code: z.string(),
});

export const VerifyOTPRequest = authedServiceRequest.merge(
  z.object({
    params: verifyOTPParams,
  }),
);
export const VerifyOTPResponse = serviceResponse.merge(
  z.object({
    twoFactorVerified: z.boolean(),
  }),
);

export const VerifyOTPForLoginRequest = serviceRequest.merge(
  z.object({
    params: verifyOTPParamsForLogin,
  }),
);

export const VerifyOTPForLoginResponse = serviceResponse.merge(
  z.object({
    twoFactorVerified: z.boolean(),
  }),
);

export const DisableOTPRequest = authedServiceRequest;
export const DisableOTPResponse = serviceResponse;

export type EnableOTPRequest = z.infer<typeof EnableOTPRequest>;
export type EnableOTPResponse = z.infer<typeof EnableOTPResponse>;

export type VerifyOTPParams = z.infer<typeof verifyOTPParams>;
export type VerifyOTPRequest = z.infer<typeof VerifyOTPRequest>;
export type VerifyOTPResponse = z.infer<typeof VerifyOTPResponse>;

export type VerifyOTPForLoginParams = z.infer<typeof verifyOTPParamsForLogin>;
export type VerifyOTPForLoginRequest = z.infer<typeof VerifyOTPForLoginRequest>;
export type VerifyOTPForLoginResponse = z.infer<
  typeof VerifyOTPForLoginResponse
>;

export type DisableOTPRequest = z.infer<typeof DisableOTPRequest>;
export type DisableOTPResponse = z.infer<typeof DisableOTPResponse>;

/** ******************************************************************************
 *  U2F
 ******************************************************************************* */

export const generateU2FRegistrationOptionsParams = z.object({});

export const generateU2FRegistrationOptionsRequest = authedServiceRequest.merge(
  z.object({
    params: generateU2FRegistrationOptionsParams,
  }),
);
export const generateU2FRegistrationOptionsResponse = serviceResponse.merge(
  z.object({
    options: z.any(),
  }),
);

export type GenerateU2FRegistrationOptionsParams = z.infer<
  typeof generateU2FRegistrationOptionsParams
>;
export type GenerateU2FRegistrationOptionsRequest = z.infer<
  typeof generateU2FRegistrationOptionsRequest
>;
export type GenerateU2FRegistrationOptionsResponse = z.infer<
  typeof generateU2FRegistrationOptionsResponse
>;

/** ******************************************************************************
 *  Verify U2F Registration
 ******************************************************************************* */

export const verifyU2FRegistrationParams = z.object({
  credential: z.object({
    id: z.string(),
    type: z.string(),
    rawId: z.any(),
    response: z.object({
      clientDataJSON: z.any(),
      attestationObject: z.any(),
    }),
  }),
  userLabel: z.string(),
});

export const verifyU2FRegistrationRequest = authedServiceRequest.merge(
  z.object({
    params: verifyU2FRegistrationParams,
  }),
);

export const verifyU2FRegistrationResponse = serviceResponse.merge(
  z.object({
    success: z.boolean(),
  }),
);

export type VerifyU2FRegistrationParams = z.infer<
  typeof verifyU2FRegistrationParams
>;
export type VerifyU2FRegistrationRequest = z.infer<
  typeof verifyU2FRegistrationRequest
>;
export type VerifyU2FRegistrationResponse = z.infer<
  typeof verifyU2FRegistrationResponse
>;

/** ******************************************************************************
 *  Generate U2F Authentication Options
 ******************************************************************************* */

export const generateU2FAuthenticationOptionsParams = z.object({
  email: z.string().email(),
});

export const generateU2FAuthenticationOptionsRequest = serviceRequest.merge(
  z.object({
    params: generateU2FAuthenticationOptionsParams,
  }),
);
export const generateU2FAuthenticationOptionsResponse = serviceResponse.merge(
  z.object({
    options: z.any(),
  }),
);

export type GenerateU2FAuthenticationOptionsParams = z.infer<
  typeof generateU2FAuthenticationOptionsParams
>;
export type GenerateU2FAuthenticationOptionsRequest = z.infer<
  typeof generateU2FAuthenticationOptionsRequest
>;
export type GenerateU2FAuthenticationOptionsResponse = z.infer<
  typeof generateU2FAuthenticationOptionsResponse
>;

/** ******************************************************************************
 *  Verify U2F Authentication
 ******************************************************************************* */

export const verifyU2FAuthenticationParams = z.object({
  response: z.any(),
  email: z.string().email(),
});

export const verifyU2FAuthenticationRequest = serviceRequest.merge(
  z.object({
    params: verifyU2FAuthenticationParams,
  }),
);

export const verifyU2FAuthenticationResponse = serviceResponse.merge(
  z.object({
    success: z.boolean(),
  }),
);

export type VerifyU2FAuthenticationParams = z.infer<
  typeof verifyU2FAuthenticationParams
>;
export type VerifyU2FAuthenticationRequest = z.infer<
  typeof verifyU2FAuthenticationRequest
>;
export type VerifyU2FAuthenticationResponse = z.infer<
  typeof verifyU2FAuthenticationResponse
>;

/** ******************************************************************************
 *  Remove U2F Key
 ******************************************************************************* */

export const removeU2FKeyParams = z.object({
  keyId: z.string(),
});

export const removeU2FKeyRequest = authedServiceRequest.merge(
  z.object({
    params: removeU2FKeyParams,
  }),
);
export const removeU2FKeyResponse = serviceResponse;

export type RemoveU2FKeyParams = z.infer<typeof removeU2FKeyParams>;
export type RemoveU2FKeyRequest = z.infer<typeof removeU2FKeyRequest>;
export type RemoveU2FKeyResponse = z.infer<typeof removeU2FKeyResponse>;

/** ******************************************************************************
 *  Disable U2F
 ******************************************************************************* */

export const disableU2FRequest = authedServiceRequest;
export const disableU2FResponse = serviceResponse;

export type DisableU2FRequest = z.infer<typeof disableU2FRequest>;
export type DisableU2FResponse = z.infer<typeof disableU2FResponse>;

/** ******************************************************************************
 *  Add U2F Key
 ******************************************************************************* */

export const addU2FKeyParams = z.object({
  keyData: z.any(),
});

export const addU2FKeyRequest = authedServiceRequest.merge(
  z.object({
    params: addU2FKeyParams,
  }),
);
export const addU2FKeyResponse = serviceResponse;

export type AddU2FKeyParams = z.infer<typeof addU2FKeyParams>;
export type AddU2FKeyRequest = z.infer<typeof addU2FKeyRequest>;
export type AddU2FKeyResponse = z.infer<typeof addU2FKeyResponse>;

/** ******************************************************************************
 *  Check U2F Enabled
 ******************************************************************************* */

export const checkU2FEnabledParams = z.object({
  email: z.string().email(),
});

export const checkU2FEnabledRequest = serviceRequest.merge(
  z.object({
    params: checkU2FEnabledParams,
  }),
);
export const checkU2FEnabledResponse = serviceResponse.merge(
  z.object({
    u2fEnabled: z.boolean(),
  }),
);

export type CheckU2FEnabledParams = z.infer<typeof checkU2FEnabledParams>;
export type CheckU2FEnabledRequest = z.infer<typeof checkU2FEnabledRequest>;
export type CheckU2FEnabledResponse = z.infer<typeof checkU2FEnabledResponse>;
