import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FiCheck, FiKey, FiLock, FiMail, FiUser } from "react-icons/fi";
import { RxDiscordLogo } from "react-icons/rx";

import KeyIcon from "~/assets/key.svg";
import { DISCORD_CLIENT_ID, DISCORD_REDIRECT_URI } from "~/lib/const";
import { api } from "~/lib/trpc";
import useUser from "~/ui/hooks/useUser.hook";
import SettingsLayout from "~/ui/layouts/Settings.layout";
import Disable2FAConfirmationModal from "./Disable2FAConfirmationModal";
import TwoFactorAuthModal from "./TwoFactorAuthModal";
import U2FAuthModal from "./U2FAuthModal";

const AccountSettingsPage: React.FC = () => {
  const {
    isOpen: isEnable2FAOpen,
    onOpen: onEnable2FAOpen,
    onClose: onEnable2FAClose,
  } = useDisclosure();
  const {
    isOpen: isDisable2FAOpen,
    onOpen: onDisable2FAOpen,
    onClose: onDisable2FAClose,
  } = useDisclosure();
  const {
    isOpen: isEnableU2FOpen,
    onOpen: onEnableU2FOpen,
    onClose: onEnableU2FClose,
  } = useDisclosure();
  const {
    isOpen: isDisableU2FOpen,
    onOpen: onDisableU2FOpen,
    onClose: onDisableU2FClose,
  } = useDisclosure();
  const [removeKeyIndex, setRemoveKeyIndex] = useState<number | null>(null);
  const {
    isOpen: isRemoveU2FKeyOpen,
    onOpen: onRemoveU2FKeyOpen,
    onClose: onRemoveU2FKeyClose,
  } = useDisclosure();
  const { user, refreshUser } = useUser();
  const toast = useToast();

  const [disableModalProps, setDisableModalProps] = useState({
    title: "",
    description: "",
    onConfirm: () => Promise.resolve(),
  });

  const disable2FAMutation = api.user.disableOTP.useMutation();

  const handleEnable2FA = () => {
    onEnable2FAOpen();
  };

  const handleDisable2FA = () => {
    setDisableModalProps({
      title: "Disable Two-Factor Authentication (OTP)",
      description:
        "Are you sure you want to disable two-factor authentication? This will make your account less secure.",
      onConfirm: confirmDisable2FA,
    });
    onDisable2FAOpen();
  };

  const handleDisableU2F = () => {
    setDisableModalProps({
      title: "Disable Universal 2nd Factor (U2F)",
      description:
        "Are you sure you want to disable U2F? This will remove all your registered U2F keys and make your account less secure.",
      onConfirm: confirmDisableU2F,
    });
    onDisableU2FOpen();
  };

  const handleEnableU2F = () => {
    onEnableU2FOpen();
  };

  const removeU2FKeyMutation = api.user.removeU2FKey.useMutation();
  const disableU2FMutation = api.user.disableU2F.useMutation();

  const handleRemoveU2FKey = (index: number) => {
    setRemoveKeyIndex(index);
    onRemoveU2FKeyOpen();
  };

  const confirmRemoveU2FKey = async () => {
    if (removeKeyIndex === null) return;
    try {
      await removeU2FKeyMutation.mutateAsync({
        keyId: user?.u2fKeys?.[removeKeyIndex]?.credentialID ?? "",
      });
      await refreshUser();
      toast({
        title: "U2F Key Removed",
        description: "The selected U2F key has been removed from your account.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error removing U2F key:", error);
      toast({
        title: "Error",
        description: "Failed to remove U2F key. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      onRemoveU2FKeyClose();
      setRemoveKeyIndex(null);
    }
  };

  const confirmDisable2FA = async () => {
    try {
      await disable2FAMutation.mutateAsync();
      await refreshUser();
      onDisable2FAClose();
      toast({
        title: "2FA Disabled",
        description:
          "Two-factor authentication has been disabled for your account.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error disabling 2FA:", error);
      toast({
        title: "Error",
        description:
          "Failed to disable two-factor authentication. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const confirmDisableU2F = async () => {
    try {
      await disableU2FMutation.mutateAsync();
      await refreshUser();
      onDisableU2FClose();
      toast({
        title: "U2F Disabled",
        description: "U2F has been disabled for your account.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error disabling U2F:", error);
      toast({
        title: "Error",
        description: "Failed to disable U2F. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const unlinkDiscordMutation = api.user.unlinkDiscordAccount.useMutation();

  const handleLinkDiscord = () => {
    const state = user?._id;
    const url = `https://discord.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&redirect_uri=${encodeURIComponent(DISCORD_REDIRECT_URI)}&response_type=code&scope=identify+email&state=${state}`;
    window.location.href = url;
  };

  const handleUnlinkDiscord = async () => {
    try {
      await unlinkDiscordMutation.mutateAsync();
      await refreshUser();
      toast({
        title: "Success",
        description: "Discord account unlinked successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error unlinking Discord account:", error);
      toast({
        title: "Error",
        description: "Failed to unlink Discord account. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <SettingsLayout
      title="Account Settings"
      description="Manage your Kuzco account settings"
    >
      <Modal isOpen={isRemoveU2FKeyOpen} onClose={onRemoveU2FKeyClose}>
        <ModalOverlay />
        <ModalContent backgroundColor="gray.1000">
          <ModalHeader>Remove U2F Key</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to remove this U2F key?</ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onRemoveU2FKeyClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={confirmRemoveU2FKey}>
              Remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <TwoFactorAuthModal isOpen={isEnable2FAOpen} onClose={onEnable2FAClose} />
      <Disable2FAConfirmationModal
        isOpen={isDisable2FAOpen || isDisableU2FOpen}
        onClose={() => {
          onDisable2FAClose();
          onDisableU2FClose();
        }}
        title={disableModalProps.title}
        description={disableModalProps.description}
        onConfirm={disableModalProps.onConfirm}
      />
      <U2FAuthModal isOpen={isEnableU2FOpen} onClose={onEnableU2FClose} />
      <VStack spacing={6} align="stretch">
        <Divider />
        <Box>
          <Heading as="h2" size="md" mb={2}>
            <Flex alignItems="center">
              <FiUser />
              <Text ml={2}>Account Information</Text>
            </Flex>
          </Heading>
          <Text>Name: {user?.firstName ?? "Not set"}</Text>
          <Text>Username: {user?.username ?? "Not set"}</Text>
          <Text>Email: {user?.email}</Text>
        </Box>

        <Divider />

        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <FiMail />
              <Text fontSize="xl" ml={2}>
                Email Verification
              </Text>
            </Flex>
            {user?.emailVerifiedAt ? (
              <Flex alignItems="center" color="green.500">
                <FiCheck />
                <Text ml={2}>Verified</Text>
              </Flex>
            ) : (
              <Link href={"/verify-email"} target="_blank">
                <Button variant="secondary">Verify Email</Button>
              </Link>
            )}
          </Flex>
        </Box>

        <Divider />

        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <RxDiscordLogo />
              <Text fontSize="xl" ml={2}>
                Discord Connection
              </Text>
            </Flex>
            {user?.discordId ? (
              <Flex flexDirection="column" alignItems="flex-end">
                <Flex alignItems="center" color="green.500">
                  <FiCheck />
                  <Text ml={2}>Connected</Text>
                  <Text ml={2} fontSize="sm">
                    "{user.discordUsername}"
                  </Text>
                </Flex>
                <Button
                  mt={2}
                  onClick={handleUnlinkDiscord}
                  variant="outline"
                  size="sm"
                >
                  Unlink Discord
                </Button>
              </Flex>
            ) : (
              <Button onClick={handleLinkDiscord} variant="secondary">
                Link Discord
              </Button>
            )}
          </Flex>
        </Box>
        <Divider />

        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <FiLock />
              <Text fontSize="xl" ml={2}>
                Two-Factor Authentication (OTP)
              </Text>
            </Flex>
            {user?.otpSecretVerifiedAt != null ? (
              <Flex alignItems="center" color="green.500">
                <FiCheck />
                <Text ml={1} mr={2}>
                  ON
                </Text>
                <Button
                  onClick={handleDisable2FA}
                  ml={2}
                  variant="outline"
                  colorScheme="red"
                  size="sm"
                >
                  Disable OTP
                </Button>
              </Flex>
            ) : (
              <Button onClick={handleEnable2FA} variant="secondary">
                Enable OTP
              </Button>
            )}
          </Flex>
        </Box>

        <Divider />

        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <FiKey />
              <Text fontSize="xl" ml={2}>
                Universal 2nd Factor (U2F)
              </Text>
            </Flex>
            {user?.u2fKeys && user.u2fKeys.length > 0 ? (
              <Flex alignItems="center" color="green.500">
                <FiCheck />
                <Text ml={1} mr={2}>
                  ON
                </Text>
                <Button
                  onClick={handleDisableU2F}
                  ml={2}
                  variant="outline"
                  colorScheme="red"
                  size="sm"
                >
                  Disable U2F
                </Button>
              </Flex>
            ) : (
              <Button onClick={handleEnableU2F} variant="secondary">
                Enable U2F
              </Button>
            )}
          </Flex>
        </Box>
      </VStack>
      {user?.u2fKeys && user.u2fKeys.length > 0 && (
        <Box mt={4}>
          <Flex justifyContent="space-between" alignItems="center" mb={2}>
            <Text fontStyle="italic" color="gray.500">
              Registered U2F Keys, add additional keys to make your account more
              secure
            </Text>
            <Button onClick={handleEnableU2F} variant="secondary" size="sm">
              Add Key
            </Button>
          </Flex>
          <Box mt={4} />
          <Divider />
          <Box mt={4} />
          <VStack align="stretch" spacing={3}>
            {user.u2fKeys.map((key, index) => (
              <Flex
                key={index}
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex alignItems="center">
                  {/* <Text mr={2}>#{index + 1} </Text> */}
                  <Image src={KeyIcon} width="20px" height="20px" />
                  <Text ml={2}>{key.label}</Text>
                </Flex>
                <Button
                  onClick={() => handleRemoveU2FKey(index)}
                  size="sm"
                  variant="outline"
                  colorScheme="red"
                >
                  Remove
                </Button>
              </Flex>
            ))}
          </VStack>
        </Box>
      )}
    </SettingsLayout>
  );
};

export default AccountSettingsPage;
