import { atom } from "jotai";
import { atomWithDefault } from "jotai/utils";
import posthog from "posthog-js";

import type { LoginUserParams, RegisterUserParams, User } from "@kuzco/models";
import { utils } from "@kuzco/utils";

import { trpc } from "~/lib/trpc";

const registerUserParams = atomWithDefault<RegisterUserParams>((get) =>
  get(
    atom<RegisterUserParams>({
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
      turnstileToken: "",
    }),
  ),
);

const loginUserParams = atomWithDefault<LoginUserParams>((get) =>
  get(
    atom<LoginUserParams>({
      email: "",
      password: "",
    }),
  ),
);

const user = atomWithDefault<User | null>((get) =>
  get(
    atom(async () => {
      try {
        const { user } = await trpc.user.get.query();
        if (user != null) {
          const properties = utils.getPosthogUserProperties(user);
          posthog.identify(user._id, properties);
        }
        return user ?? null;
      } catch (e) {
        console.error("Failed to fetch user: ", e);
        return null;
      }
    }),
  ),
);

const UserState = {
  registerUserParams,
  loginUserParams,
  user,
};

export default UserState;
