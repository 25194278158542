import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Flex, Spinner, Stack, Text, useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { StatusCodes } from "@kuzco/models";
import { assertUnreachable, ERROR_MESSAGES } from "@kuzco/utils";

import PosthogEvent from "~/constants/PosthogEvent.constants";
import { api } from "~/lib/trpc";
import ResendEmailVerificationComponent from "~/ui/components/ResendEmailVerificationComponent";
import { withSuspense } from "~/ui/hoc/withSuspense";
import useTracking from "~/ui/hooks/useTracking.hook";
import MaintenanceModeLayout from "~/ui/layouts/MaintenanceMode.layout";
import useLoginSuccessRedirect from "../ui/hooks/useLoginSuccessRedirect.hook";
import useUser from "../ui/hooks/useUser.hook";

type VerificationState = "loading" | "success" | "error" | "error-allow-retry";

const VerifyEmailConfirmationPage = () => {
  const initialMountRef = useRef(false);
  const redirectTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { emailVerificationToken } = useParams();
  const { loginSuccessRedirect } = useLoginSuccessRedirect();
  const toast = useToast();
  const { track } = useTracking();
  const { isAuthenticated, user, refreshUser } = useUser();
  const [verificationState, setVerificationState] =
    useState<VerificationState | null>(null);
  const [lastEmailRequestTimestamp, setLastEmailRequestTimestamp] =
    useState<Date | null>(user?.emailVerificationTokenRequestedAt ?? null);

  const verifyEmailVerificationTokenMutation =
    api.user.verifyEmailVerificationToken.useMutation();
  const sendEmailVerificationLinkMutation =
    api.user.sendEmailVerificationLink.useMutation();

  const handleValidateEmailVerificationToken = useCallback(
    async (token: string) => {
      setVerificationState("loading");
      try {
        const response = await verifyEmailVerificationTokenMutation.mutateAsync(
          {
            token,
          },
        );
        if (response.status !== StatusCodes.OK) {
          setVerificationState("error");
          if (
            response.error?.message ===
            ERROR_MESSAGES.EMAIL_VERIFICATION_TOKEN_EXPIRED
          ) {
            if (isAuthenticated) {
              setVerificationState("error-allow-retry");
              toast({
                title: "Token expired.",
                description:
                  "Please request another verification email and try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              return;
            } else {
              setVerificationState("error");
              toast({
                title: "Token expired.",
                description:
                  "Please log in and request another verification email.",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              return;
            }
          }
          throw new Error("Token verification failed.");
        }

        track({
          event: PosthogEvent.AuthEmailVerificationSuccess,
        });
        setVerificationState("success");
        toast({
          title: "Success",
          description: "Your email has been verified.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        if (isAuthenticated) {
          void refreshUser();
          redirectTimerRef.current = setTimeout(() => {
            void loginSuccessRedirect();
          }, 3000);
        }
      } catch (err) {
        track({
          event: PosthogEvent.AuthEmailVerificationFailure,
        });
        setVerificationState(isAuthenticated ? "error-allow-retry" : "error");
        toast({
          title: "Failed to verify email.",
          description:
            "Token could not be verified. Please try to verify your email again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [
      isAuthenticated,
      loginSuccessRedirect,
      refreshUser,
      setVerificationState,
      toast,
      track,
      verifyEmailVerificationTokenMutation,
    ],
  );

  const handleResendVerificationEmail = useCallback(async () => {
    try {
      const response = await sendEmailVerificationLinkMutation.mutateAsync();
      if (response.status !== StatusCodes.OK) {
        throw new Error(
          response.error?.message ?? "Failed to send email verification.",
        );
      }
      setLastEmailRequestTimestamp(new Date());
      toast({
        title: "Success",
        description: "Email verification sent. Please check your email.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Failed to resend verification email.",
        description: "Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast, sendEmailVerificationLinkMutation]);

  useEffect(() => {
    return () => {
      if (redirectTimerRef.current) {
        clearTimeout(redirectTimerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (initialMountRef.current === true) {
      return;
    }

    if (emailVerificationToken != null) {
      if (verificationState == null) {
        void handleValidateEmailVerificationToken(emailVerificationToken);
      }
    } else {
      setVerificationState("error");
    }

    return () => {
      initialMountRef.current = true;
    };
  }, [
    emailVerificationToken,
    handleValidateEmailVerificationToken,
    verificationState,
  ]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const refreshedUser = await refreshUser();
      if (refreshedUser?.emailVerifiedAt != null) {
        void loginSuccessRedirect();
      }
    }, 10_000);
    return () => clearInterval(intervalId);
  }, [refreshUser, loginSuccessRedirect]);

  return (
    <MaintenanceModeLayout>
      <Box
        backgroundColor="gray.1000"
        height="100vh"
        width="100vw"
        display="flex"
        justifyContent="center"
      >
        <Flex
          position="relative"
          top={{ base: "0", md: "96px" }}
          width={{ base: "100%", md: "420px" }}
          height="fit-content"
          flexDirection="column"
          alignItems="center"
          borderRadius="8px"
          boxSizing="border-box"
        >
          <Box height="24px" />
          {(() => {
            switch (verificationState) {
              case "loading":
                return (
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Spinner />
                    <Text variant="30-bold" color="white">
                      Verifying email...
                    </Text>
                  </Stack>
                );
              case "success":
                return (
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    gap={6}
                  >
                    <Text variant="30-bold" color="white">
                      Email verified!
                    </Text>
                    <Text variant="16-reg" color="white">
                      {isAuthenticated
                        ? "You will be redirected to the app momentarily."
                        : "Please return to the original application to continue."}
                    </Text>
                  </Stack>
                );
              case "error-allow-retry":
              case "error":
                return (
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    gap={8}
                  >
                    <Text variant="30-bold" color="white">
                      Email verification failed
                    </Text>
                    {verificationState === "error-allow-retry" && (
                      <ResendEmailVerificationComponent
                        handleRequestEmailVerification={
                          handleResendVerificationEmail
                        }
                        lastEmailRequestTimestamp={lastEmailRequestTimestamp}
                      />
                    )}
                  </Stack>
                );
              case null:
                return null;
              default:
                return assertUnreachable(verificationState);
            }
          })()}
        </Flex>
      </Box>
    </MaintenanceModeLayout>
  );
};

export default withSuspense(VerifyEmailConfirmationPage);
